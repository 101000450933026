import React from 'react';
import { NavLink, withRouter } from 'react-router-dom';
import { Navbar, Nav } from 'react-bootstrap';
import '../header.scss';

const Navigation = () => {
    return (

        <Navbar className="justify-content-end" variant="light" expand={"xl"}>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
                <Nav className="ml-auto pr-2">
                    <NavLink className="nav-link" exact to="/">FORSIDE</NavLink>
                    <NavLink className="nav-link" to="/services">SERVICES</NavLink>
                    {/* <NavLink className="nav-link" to="/priser">PRISER</NavLink> */}
                    <NavLink className="nav-link" to="/kontakt">KONTAKT</NavLink>
                </Nav>
            </Navbar.Collapse>
        </Navbar>

    )
}

export default withRouter(Navigation);
