import React from 'react';

const Product = (props) => {
    return (



        <div className="col-xs-12 col-sm-6 col-md-4 col-lg-4 item">

            <i title="HTML 5" className={props.productsProps.icon}></i>
            <h4>{props.productsProps.title}</h4>
            <p>{props.productsProps.text}</p>

        </div>













    )
}


export default Product;
