import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import Products from './Products';
import HeaderBanner from '../HeaderBanner/HeaderBanner';
import FooterCover from '../FooterCover/FooterCover';
import '../Services/services.scss';

const Services = () => {

    const [products] = useState([
        {
            icon: "fas fa-book", title: "FINANSBOGHOLDERI", text: "Styr på konti i kontoplanen og alle bankens og kassens transaktioner.", id: 1,
        },
        {
            icon: "fas fa-suitcase", title: "DEBITORBOGHOLDERI", text: "Fakturering, kontrol over betalinger og rykning."
        },
        {
            icon: "fas fa-money-check", title: "KREDITORBOGHOLDERI", text: "Betaling af regninger og opfølgning."
        },
        {
            icon: "fas fa-hands-helping", title: "LØNASSISTANCE", text: "Lønberegning i lønsystemer og opfølgning."
        },
        {
            icon: "fas fa-poll", title: "AFSTEMNINGER", text: "Assistance ved afstemninger af banken, kassen, løn, moms, gæld, debitorer, kreditorer mm."
        },
        // {
        //     icon: "fas fa-mail-bulk", title: "DIVERSE KONTOROPGAVER", text: "Arkivering, håndtering af post, scanning, og kopiering mm."
        // },
        {
            icon: "fas fa-calculator", title: "REGNSKABER", text: "Månedlige- og kvartals, klargøring af årsregnskaber til revision."
        },
        // {
        //     icon: "fas fa-phone", title: "KONTAKT MIG", text: "Hvis du har nogle spørgsmål er du altid velkommen til at kontakte mig."
        // },

    ]);


    return (

        <div className="container-fluid">

            <Helmet>
                <title>Bogholderiverden v/Ludmilla Olsen - Services</title>
                <meta name="description" content="Her kan du se hvad jeg kan tilbyde dit firma af services. Finansbogholderi, Debitorbogholderi, Kreditorbogholdeeri, Lønassistance, Afstemninger, Regnskab" />
            </Helmet>

            <div className="row">

                <HeaderBanner />

            </div>

            <main className="container text-center">


                <div className="row">
                    <div className="col-12 col-md-12 col-lg-12">
                        <h1 id="services" className="text-center">MINE SERVICES</h1>
                        <p className="text-center offer">Her kan du se hvad jeg kan tilbyde dit firma af services</p>
                    </div>
                </div>

                <Products allProductsProps={products} />



            </main>

            <FooterCover />



        </div>
    )
}

export default Services;
