import React from 'react';
import '../IconsSection/icons.scss';

const IconsSection = () => {
    return (
        <div id="icons" className="container padding">


            <div className="row text-center padding">
                <div className="col-xs-12 col-sm-6 col-md-3 col-lg-3">
                    <i title="BOGFØRING" className="fas fa-book"></i>
                    <h3>BOGFØRING</h3>

                </div>

                <div className="col-xs-12 col-sm-6 col-md-3 col-lg-3">
                    <i title="DEBITORER" className="fas fa-chart-line"></i>
                    <h3>DEBITORER</h3>

                </div>

                <div className="col-xs-12 col-sm-6 col-md-3 col-lg-3">
                    <i title="KREDITORER" className="fas fa-users"></i>
                    <h3>KREDITORER</h3>

                </div>

                <div className="col-xs-12 col-sm-6 col-md-3 col-lg-3">
                    <i title="MOMS" className="fas fa-chart-pie"></i>
                    <h3>MOMS</h3>

                </div>


            </div>



        </div>
    )
}

export default IconsSection;
