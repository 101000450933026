import React from 'react';
import '../HeaderBanner/headerbanner.scss';

const HeaderBanner = () => {
    return (
        <div id="header-img" className="col-12">

        </div>
    )
}

export default HeaderBanner;
