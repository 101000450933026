import React from 'react'

const Form = () => {
    return (

        <div className="row">

            <div id="form-col" className="col-md-9 mb-md-0 mb-5">

                <form id="contact-form" name="contact-form" action="https://formspree.io/ludmillao@outlook.com" method="post" >

                    <div className="row">

                        <div className="col-md-6">
                            <div className="md-form mb-0">
                                <label htmlFor="name" className="">Navn</label>
                                <input type="text" name="Navn" id="name" className="form-control" required={true} />
                            </div>
                        </div>

                        <div className="col-md-6">
                            <div className="md-form mb-0">
                                <label htmlFor="email" className="">Email</label>
                                <input type="email" name="_replyto" className="form-control" id="email" required={true} />
                            </div>
                        </div>
                    </div>

                    <div className="row">

                        <div className="col-md-12">
                            <label htmlFor="subject" className="mt-4">Emne</label>
                            <div className="md-form mb-0">
                                <input type="text" name="Emne" id="subject" className="form-control" required={true} />
                            </div>
                        </div>

                    </div>

                    <div className="row">
                        <div className="col-md-12">

                            <div className="md-form">
                                <label className="mt-4" htmlFor="message">Besked</label>
                                <textarea required={true} type="text" name="Besked" id="message" rows="4" className="form-control md-textarea"></textarea>

                                <input type="hidden" name="_subject" id="email-subject" value="Contact Form Submission" />
                            </div>
                        </div>
                    </div>

                    <div className="text-center text-md-left mt-4">
                        <button className="button" type="submit" value="submit">Send</button>
                    </div>



                </form>

            </div>

            <div className="col-md-3 text-center">
                <ul class="list-unstyled mb-0">



                    <li aria-label="mailto" href="mailto:ludmillao@outlook.com">
                        <a href="mailto:ludmillao@outlook.com">
                            <li>
                                <div className="envelope">
                                    <i className="fas fa-envelope mt-4 fa-2x"></i>

                                </div>
                            </li>
                        </a>

                        <p>ludmillao@outlook.com
                            </p>

                    </li>



                    <li aria-label="phone">

                        <li>
                            <div className="phone">
                                <a href="tel:28682890">
                                    <i className="fas fa-phone mt-4"></i>
                                </a>
                            </div>
                        </li>

                        <p className="mt-1">Tlf: 28 68 28 90
                            </p>
                    </li>






                </ul>

            </div>



        </div>

    )
}

export default Form;
