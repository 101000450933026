import React from 'react';
import { Helmet } from 'react-helmet';
import Cover from '../Cover/Cover';
import IconSection from '../IconsSection/IconsSection';
import MainSection from '../MainSection/MainSection';

const Home = () => {
    return (
        <div className="wrapper">

            <Helmet>
                <title>Bogholderiverden v/Ludmilla Olsen</title>
                <meta name="description" content="Allround bogholderi og regnskab, så du kan fokusere på forrretningen - Bogføring, Debitorer, Kreditorerer, Moms" />
            </Helmet>
            <Cover />

            <main className="main">
                <IconSection />
                <MainSection />


            </main>

        </div>
    )
}


export default Home;
