import React from 'react';
import { NavLink, withRouter } from 'react-router-dom';
import "animate.css/animate.min.css";
import '../Cover/cover.scss';

const Cover = () => {
    return (
        <div className="content-img">


            <div className="title text-center">

                <h1 className="text-center animated fadeIn">Velkommen til BOGHOLDERIVERDEN</h1>



                <h2 className="text-center">Allround bogholderi og regnskab, så du kan fokusere på forretningen.</h2>


                <NavLink to="/services"> <button className="button">Læs mere</button></NavLink>


            </div>
        </div>
    )
}

export default withRouter(Cover);
