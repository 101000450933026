import React from 'react';
import '../Footer/footer.scss';
import { NavLink, withRouter } from 'react-router-dom';

const Footer = () => {
    return (

        <footer id="footer" className="footer-1">
            <div className="main-footer widgets-dark typo-light">
                <div className="container">
                    <div className="row">
                        <div className="col-xs-12 col-sm-6 col-md-3">
                            <div className="widget subscribe no-box">
                                <h5 className="widget-title">BOGHOLDERIVERDEN<span></span></h5>
                                <p>Skal du have hjælp til at bogføre din forretning? Så står jeg klar til at hjælpe dig</p>
                            </div>

                        </div>

                        <div className="col-xs-12 col-sm-6 col-md-3">
                            <div className="widget no-box">
                                <h5 className="widget-title">Nyttige links<span></span></h5>
                                <ul className="tumbnail-widget">
                                    <li>
                                        <div className="thumb-content"><a href="http://www.skat.dk">SKAT</a></div>
                                    </li>
                                    <li>
                                        <div className="thumb-content"><a href="https://indberet.virk.dk/">Digital indgang til det offentlige</a></div>
                                    </li>
                                    <li>
                                        <div className="thumb-content"><a href="https://datacvr.virk.dk/data/">Det offentlige virksomhedsregistre</a></div>
                                    </li>
                                    <li>
                                        <div className="thumb-content"><a href="https://www.amino.dk/">Fællesksab for iværksætteri</a></div>
                                    </li>

                                </ul>

                            </div>

                        </div>

                        <div className="col-xs-12 col-sm-6 col-md-3">
                            <div className="widget no-box">
                                <h5 className="widget-title">Tilbud<span></span></h5>
                                <p>Få et uforpligtende tilbud</p>
                                <NavLink to="/kontakt" className="btn">Klik mig</NavLink>

                            </div>

                        </div>

                        <div className="col-xs-12 col-sm-6 col-md-3">

                            <div className="widget no-box">
                                <h5 className="widget-title">Kontakt mig<span></span></h5>

                                <p><a href="mailto:ludmillao@outlook.com"
                                    title="glorythemes">ludmillao@outlook.com</a></p>

                                <p><a href="tel:28682890"
                                    title="glorythemes">Tlf: 28 68 28 90</a></p>


                            </div>

                        </div>

                    </div>
                </div>
            </div>

            <div className="footer-copyright">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 text-center">
                            <p>BOGHOLDERIVERDEN © 2020. Alle rettigheder forbeholdes.</p>
                            <p>CVR 27 56 02 37</p>
                        </div>
                    </div>
                </div>
            </div>




        </footer>
    )
}

export default withRouter(Footer);
