import React from 'react';
import { NavLink, withRouter } from 'react-router-dom';
import '../FooterCover/footercover.scss';


const FooterCover = () => {
    return (
        <div className="con text-center">

            <h2 className="text-center">KONTAKT MIG FOR ET UDFORPLIGTENDE TILBUD</h2>

            <NavLink to="/kontakt"> <button className="button">KONTAKT</button></NavLink>

        </div>
    )
}

export default withRouter(FooterCover);
