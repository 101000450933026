import React from 'react';
import '../MainSection/mainsection.scss';
import ScrollAnimation from 'react-animate-on-scroll';
import "animate.css/animate.min.css";
import Meeting from '../../images/meetingtable.png';

const MainSection = () => {
    return (

        <div id="con" className="container-fluid">






            <div className="box1">
                <ScrollAnimation animateIn='fadeInLeft'>
                    <h3>Lad mig være din samarbejdspartner og stå for bogføring og regnskab:</h3>

                    <div className="align-center">

                        <div className="align-left">
                            <p>- Styr på tallene</p>
                            <p>- Dokumenteret erfaring som regnskabsassistent og bogholder
                                                    </p>
                            <p>- Uddannet som Merkonom i Økonomistyring</p>
                            <p>- Resultatorienteret</p>
                            <p>- Pålidelig, stabil, positiv</p>
                        </div>
                    </div>
                </ScrollAnimation>
            </div>




            <div className="box2">
                <ScrollAnimation animateIn='fadeInRight'>
                    <div className="img-container">
                        <img className="img-fluid" src={Meeting} alt="Mødebord" />
                    </div>
                </ScrollAnimation>
            </div>

        </div>

    )
}

export default MainSection;
