import React from 'react';
import { NavLink, withRouter } from 'react-router-dom';
import Navbar from './Navbar/Navigation';
import './header.scss';
import Logo from '../../images/logo.png';

const Header = () => {
    return (
        <header className="header">


            <div className="logo">

                <NavLink className="img-link" to="/">
                    <img className="img-fluid" src={Logo} alt="BOGHOLDERIVERDEN LOGO" />
                </NavLink>



            </div>


            <Navbar />
        </header>
    )
}

export default withRouter(Header);
