import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import Header from './components/Header/Header';
import Home from './components/Home/Home';
import Services from './components/Services/Services';
// import Prices from './components/Prices/Prices';
import Contact from './components/Contact/Contact';
import Footer from './components/Footer/Footer';

const App = () => {
  return (
    <BrowserRouter>

      <div className="App">
        <Header />

        <Switch>
          <Route exact path="/" component={Home} />
          <Route path="/services" component={Services} />
          {/* <Route path="/priser" component={Prices} /> */}
          <Route path="/kontakt" component={Contact} />


        </Switch>

        <Footer />



      </div>
    </BrowserRouter>
  );
}

export default App;
