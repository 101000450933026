import React from 'react';
import { Helmet } from 'react-helmet';
import Form from '../Contact/Form';
import '../Contact/contact.scss';
import HeaderBanner from '../HeaderBanner/HeaderBanner';

const Contact = () => {
    return (

        <div className="container-fluid">


            <Helmet>
                <title>Bogholderiverden v/Ludmilla Olsen - Kontakt</title>
                <meta name="description" content="Bogholderiverden - ludmillao@outlook.com, 28 68 28 90" />
            </Helmet>

            <div className="row">
                <HeaderBanner />
            </div>
            <main className="main-contact">
                <div className="container">
                    <section className="mb-4">
                        <h1 id="contact" className="h2-responsive font-weight-bold text-center my-4">KONTAKT</h1>


                        <Form />
                    </section>
                </div>
            </main>
        </div>
    )
}

export default Contact;
